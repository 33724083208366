import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"
import imgLove from '../images/dibus/love.svg'

const ProyectosPage = () => (
  <Layout className="page-proyectos">
    <SEO title="Proyectos" />

    <section className="bloque bloque-main bloque-proyectos-main">
		<div className="container">
		 	<h1 className="main-title">Proyectos</h1>
		 	<p>Si has llegado hasta aquí seguramente es porque estás interesado en ver como trabajamos y que tipo de gustos tenemos a la hora de diseñar. En esta sección verás los últimos trabajos que hemos estado haciendo. Espero que te gusten tanto como a nosotros porque realmente le ponemos mucho cariño y entusiasmo a cada proyecto que cogemos.</p>

			<div className="row">
				<div className="col-sm-6">

				</div>
				<div className="col-sm-6">

				</div>				
			</div>					
		</div>
    </section>

    <section className="bloque bloque-superpoder">
		<div className="container">
			<div className="row">
				<div className="col-sm-6 bloque-texto">
					<h2 className="title">Nuestro super poder es hacerlo todo con mucho love</h2>
				</div>
				<div className="col-sm-6 bloque-img">
					<figure>
						<img src={imgLove} title="Lo hacemos todo con mucho love" alt="" />
					</figure>
				</div>				
			</div>					
		</div>
    </section>

    <BloqueContacto />

  </Layout>
)

export default ProyectosPage